import React from "react";
import { useEffect, useContext, useState } from "react";

import App from "../App";
import "../App.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import FilterList from "../components/filterlist";
import DropDownList from "../components/dropdownlov";
import LovContext from '../components/store/lov-context';
import { Card, Button, Image, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';


function ValidacionMesasPage(props) {
    
    const [selectedPage, setSelectedPage] = useState("Main");
    const lovCtx = useContext(LovContext);
    
    let content = [];
    content.push(<Heading level={2}>En Construcción</Heading>);
    content.push(<Heading level={4}>Apareceran las mesas ingresadas por otros usuarios, que este usuario debe validar</Heading>);
    content.push(  <Image
        alt="Team Patriota"
        src="TeamPatriota.png"
        height="30%"
        width="30%"
      />
    );
    if( selectedPage === "App") {
        return( <App />);
    }
      /*
    let idRegion;
    let idComuna;
    let idCircunscripcion;
  
  
    if (lovCtx.totalLov >= 0) {
      content.push(<DropDownList url="https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getregiones" lovid="1" listname="regiones" keyfield="CODIGO" valuefield="DESCRIPCION" title="Región" placeholder="Ingrese nombre de región"/>);
    } 
    if (lovCtx.totalLov >= 1) {
      idRegion = lovCtx.itemLovSelectedValue(1 + '').value;
      let urlstr = "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getcomunas/" + idRegion ;
      content.push(<DropDownList url={urlstr} lovid="2" listname="comunas" keyfield="CODIGO" valuefield="DESCRIPCION" title="Comuna" placeholder="Ingrese nombre de comuna"/>);
    }
    if ( lovCtx.totalLov >= 2 ) {
      idComuna = lovCtx.itemLovSelectedValue(2 + '').value;
      let urlstr = "https://g3fs966u8l.execute-api.us-east-1.amazonaws.com/prod/getcircunscripciones/" + idRegion  + "/" + idComuna;
      content.push(<DropDownList url={urlstr} lovid="3" listname="circunscripciones" keyfield="CODIGO" valuefield="DESCRIPCION" title="Circunscripción" placeholder="Ingrese nombre de circunscripción"/>);
    }
    if ( lovCtx.totalLov >= 3 ) {
      idCircunscripcion = lovCtx.itemLovSelectedValue(3 + '').value;
      let urlstr = "https://g3fs966u8l.execute-api.us-east-1.amazonaws.com/prod/getinhabilitados/" + idRegion  + "/" + idComuna + "/" + idCircunscripcion;
      content.push(<FilterList url={urlstr} lovid="4" listname="inhabilitados" keyfield="CODIGO" valuefield="DESCRIPCION" title="Inhabilitado" placeholder="Ingrese nombre de persona inhabilitada que busca"/>);
    }
    */
  
    return (
      <Card variation="outlined">
        <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("App")}>Volver al Menú Principal</Button>    
        {content}
      </Card>
    );
  }
  
  export default ValidacionMesasPage;
  