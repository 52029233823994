import React from "react";
import { useEffect, useContext, useState } from "react";
import LovContext from './store/lov-context';
import { SelectField, Loader } from '@aws-amplify/ui-react'

let MY_LIST = [];

function DropDownList(props) {
//  const [search, setSearch] = useState("");
//  const [selected, setSelected] = useState({});
  const [lovlist, setLovList] = useState(MY_LIST);
  const [isLoading, setIsLoading] = useState(true);
  const [lastURL, setLastURL] = useState("");

  const lovCtx = useContext(LovContext);
/*
  const filtername = "searchFilter" + props.lovid;

  function onKeyUpHandler(e) {
    setSearch(e.target.value.toLowerCase());
  }
*/

  function onSelectItem(e, item) {
  //  setSelected(item);
  //  setSearch("");
  //  clearFilter();
    MY_LIST = [];
    // setFilterlist(MY_LIST)
    lovCtx.removeLov(props.lovid);
    lovCtx.addLov({id : props.lovid, value : item});
    lovCtx.resetCounters();
  }

  /*
  function clearFilter() {
    document.getElementById(filtername).value = "";
  }
  */

  function fetchData() {
    fetch(props.url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        MY_LIST = data[props.listname];
        setLovList(MY_LIST)
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log("useEffect");
    fetchData();
  }, [lastURL]);

  if( lastURL !== props.url) {
    setLastURL(props.url);
  }

  /*
  useEffect(() => {
    const filteredList = MY_LIST.filter((item) => {
       let all_str = `${item[props.keyfield]} ${item[props.valuefield]}`.toLowerCase();
      //return all_str.indexOf(search) > -1; // View All When Search Empty
      return all_str.indexOf(search) > -1 && search;
    });
    setFilterlist(filteredList);
  }, [props.keyfield, props.valuefield, search]);
  */

  if (isLoading) {
    return (
      <section>
       <p>Cargando...</p> 
      </section>
    );
  }
//       <select id={props.lovid} className="form-control select-class" onChange={(e) => onSelectItem(e, e.target.value)}>
//       </select>
  let defvalue = lovCtx.itemLovSelectedValue(props.lovid);
  return (
      <SelectField label={props.title} size="default" id={props.lovid} defaultValue={defvalue && defvalue !== undefined ? defvalue.value : "0"} onChange={(e) => onSelectItem(e, e.target.value)}>
        <option key="100000" value="0">Seleccione {props.title}</option>
        { lovlist &&
          lovlist !== undefined ?
          lovlist.map((item,index) => {
            return(
            <option key={index} value={item[props.keyfield]} className="list-group-item">
              {item[props.valuefield]}
            </option>
            )
          }) : "Ningún elemento"
        }
      </SelectField>
  );
}

export default DropDownList;