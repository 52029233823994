import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LovContextProvider } from './components/store/lov-context';
import { View, Text, Image, Flex, ScrollView } from "@aws-amplify/ui-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ScrollView
        as="div"
        ariaLabel="View example"
        backgroundColor="var(--amplify-colors-white)"
        borderRadius="6px"
        border="1px solid var(--amplify-colors-black)"
        boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
        color="var(--amplify-colors-blue-60)"
        height="95vh"
        maxWidth="100%"
        padding="1rem"
        width="100%"
      >
        <LovContextProvider>
          <App />
        </LovContextProvider>
      </ScrollView>
      <View as="div" textAlign="center" >
        <Flex direction="row"   alignItems="center" alignContent="center">
          <Image
            alt="Team Patriota"
            src="TeamPatriota.png"
            height="4%"
            width="4%"        
          />
          <Text>
            &copy; 2022 Democracy Sentinel by Team Patriota. All Rights Reserved.
          </Text>
          </Flex>
      </View>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
