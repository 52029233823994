import { createContext,  useState } from 'react';

const LovContext = createContext({
  listsStatus: [],
  totalLov: 0,
  listCounters: [],
  totalCounter : 0,
  fotoActa: "",
  addLov: (lovStatus) => {},  // Agrega el seleccionado a la listsStatus
  removeLov: (lovId) => {},   // Remueve de la listsStatus los elementos con identificador mayor o igual a lovId
  itemIsLov: (lovId) => {},   // Retorna si el el elemento identificado por lovId está en listsStatus
  itemLovSelectedValue: (lovId) => {},  // Retorna el valor seleccionado en el elemento identificado por lovId en listsStatus
  addCounter: (counterStatus) => {},    // Agrega un elemento de conteo en listCounters
  removeCounter: (counterId) => {},     // Remueve el elemento de conteo identificado por counterId de listCounters
  itemIsCounter: (counterId) => {},     // Retorna si el elemento de conteo identificado por counterId está en listCounters
  itemCounterValue: (counterId) => {},  // Retorna el valor value1 del elemento de conteo identificado por counterId
  itemCounterValues: (counterId) => {},  // Retorna el elemento de conteo identificado por counterId
  itemCounterAreEqual : (counterId) => {},  // Retorna si los dos valores de un elemento de conteo son identicos, para el elemento de conteo identificado por counterId en listCounters 
  sumCounters : (counterId) => {},          // Retorna la suma de los valores de los elementos de conteo con identificación igual o menor a counterId 
  resetCounters : ()  => {},                 // Resetea todos los contadores y la foto del acta
  setFotoActa : (foto) => {},               // Almacena la foto del Acta en fotoActa
  getFotoActa : ()  => {},                  // Retorna la foto del Acta contenida en fotoActa
  resetContext : () => {}                   // Resetea todo el contexto
});

export function LovContextProvider(props) {
  const [userLov, setUserLov] = useState([]);
  const [userCounter, setUserCounter] = useState([]);
  const [userFotoActa, setUserFotoActa] = useState("");



  function addLovHandler(lovStatus) {
    setUserLov((prevUserLov) => {
      return prevUserLov.concat(lovStatus);
    });
  }

  function removeLovHandler(lovId) {
    setUserLov(prevUserLov => {
      return prevUserLov.filter(lov => lov.id < lovId);
    });
  }

  function itemIsLovHandler(lovId) {
    return userLov.some(lov => lov.id === lovId);
  }

  function itemLovSelectedValueHandler(lovId) {     
    if (userLov.some(lov => lov.id === lovId)) {
        return userLov.filter(lov => lov.id === lovId)[0];
    }  
  }

  function addCounterHandler(counterStatus) {
    setUserCounter((prevUserCounter) => {
      return prevUserCounter.concat(counterStatus);
    });
  }

  function removeCounterHandler(counterId) {
    setUserCounter(prevUserCounter => {
      return prevUserCounter.filter(counter => counter.id !== counterId);
    });
  }

  function itemIsCounterHandler(counterId) {
    return userCounter.some(counter => counter.id === counterId);
  }

  function itemCounterValueHandler(counterId){
    if (userCounter.some(counter => counter.id === counterId)) {
      return userCounter.filter(counter => counter.id === counterId)[0].value1;
    }  
  }

  function itemCounterValuesHandler(counterId){
    if (userCounter.some(counter => counter.id === counterId)) {
      return userCounter.filter(counter => counter.id === counterId)[0];
    }  
  }

  function itemCounterAreEqualHandler(counterId){
    if (userCounter.some(counter => counter.id === counterId)) {
      return userCounter.filter(counter => counter.id === counterId)[0].value1 === userCounter.filter(counter => counter.id === counterId)[0].value2;
    }  
  }

  function sumCountersHandler(counterId){
    let suma = userCounter.filter(counter => counter.id <= counterId).reduce((acumulador, b) => acumulador + b.value1);
    return suma;
  }

  function setFotoActaHandler(foto){
    setUserFotoActa(foto);
  }

  function getFotoActaHandler(){
    return userFotoActa;
  }

  function resetContextHandler() {
    setUserLov([]);
    setUserCounter([]);
    setUserFotoActa("");
  }
  function resetCountersHandler() {
    setUserCounter([]);
    setUserFotoActa("");
  }

  const context = {
    listsStatus: userLov,
    totalLov: userLov.length,
    listCounters: userCounter,
    totalCounter: userCounter.length,
    fotoActa : userFotoActa,
    addLov: addLovHandler,
    removeLov: removeLovHandler,
    itemIsLov: itemIsLovHandler,
    itemLovSelectedValue: itemLovSelectedValueHandler,
    addCounter: addCounterHandler,
    removeCounter: removeCounterHandler,
    itemIsCounter: itemIsCounterHandler,
    itemCounterValue: itemCounterValueHandler,
    itemCounterValues: itemCounterValuesHandler,
    itemCounterAreEqual: itemCounterAreEqualHandler,
    sumCounters : sumCountersHandler,
    resetCounters : resetCountersHandler,
    setFotoActa : setFotoActaHandler,
    getFotoActa : getFotoActaHandler,
    resetContext : resetContextHandler
  };

  return (
    <LovContext.Provider value={context}>
      {props.children}
    </LovContext.Provider>
  );
}

export default LovContext;
