import React from "react";
import Webcam from "react-webcam";
import { useEffect, useContext, useState } from "react";
import LovContext from './store/lov-context';
import { Flex, TextField, Badge, Divider, Text, Button } from '@aws-amplify/ui-react'


function FotoActa( props ) {
//    const videoConstraints = {
//        width: 800,
//        height: 600,
//        facingMode: "environment"
//    };

    const cameraWidth = 720;
    const cameraHeight = 720;
    const aspectRatio = cameraWidth / cameraHeight;

    const videoConstraints = {
    //    width: {
    //        min: cameraWidth
    //    },
    //    height: {
    //        min: cameraHeight
    //    },
        facingMode: "environment",
        aspectRatio
    };

    const lovCtx = useContext(LovContext);
    
    const webcamRef = React.useRef();
    const [imgSrc, setImgSrc] = React.useState(lovCtx.fotoActa.length === 0 ? null : lovCtx.fotoActa);
    const [sacarFoto, setSacarFoto] = React.useState(props.sacarfoto === "1");

  
    const capture = React.useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      lovCtx.setFotoActa(imageSrc);
      setSacarFoto(false);
      setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc, lovCtx]);

    const cambiar = React.useCallback(() => {
        setSacarFoto(true);
      }, [setSacarFoto]);
  
    if(sacarFoto){
        return (
            <>
            <Divider orientation="horizontal" />
              <Webcam videoConstraints={videoConstraints} 
                // width={cameraWidth} height={cameraHeight}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                height="100%"
                width="100%"
//                videoConstraints={videoConstraints}      
              />
               <Button isFullWidth={true} variation="primary" onClick={capture}>Capturar Acta</Button>
               <Divider orientation="horizontal" />
               <Divider orientation="horizontal" />
               <Divider orientation="horizontal" />
           </>
          );
      
    }
    else if( props.solomostraracta === "1" ){
        return (
            <>
            <Divider orientation="horizontal" />
              {imgSrc && (
                <img alt="Acta" src={imgSrc}/>
              )}
            </>
          );
      
    }
    else {
      return (
        <>
        <Divider orientation="horizontal" />
          {imgSrc && (
            <img alt="Acta" src={imgSrc}/>
          )}
          <Button isFullWidth={true} onClick={cambiar}>Volver a Capturar Acta</Button>
          <Divider orientation="horizontal" />
          <Divider orientation="horizontal" />
          <Divider orientation="horizontal" />
        </>
      );
    }
  }

export default FotoActa;