import React from "react";
import { useEffect, useContext, useState } from "react";
import LovContext from './store/lov-context';
import { TextField } from '@aws-amplify/ui-react';

let MY_LIST = [];

function FilterList(props) {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [filterlist, setFilterlist] = useState(MY_LIST);
  const [isLoading, setIsLoading] = useState(true);
  const [lastURL, setLastURL] = useState("");

  const lovCtx = useContext(LovContext);

  const filtername = "searchFilter" + props.lovid;

  function onKeyUpHandler(e) {
    setSearch(e.target.value.toLowerCase());
  }

  function onSelectItem(e, item) {
    setSelected(item);
    setSearch("");
    clearFilter();
    // MY_LIST = [];
    // setFilterlist(MY_LIST)
    lovCtx.removeLov(props.lovid)
    lovCtx.addLov({ id : props.lovid, value : item[props.keyfield]})
  }

  function clearFilter() {
    document.getElementById(filtername).value = "";
  }

  function fetchData() {
    fetch(props.url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        MY_LIST = data[props.listname];
        // setFilterlist(MY_LIST)
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log("useEffect");
    fetchData();
  }, [lastURL]);


  useEffect(() => {
    const filteredList = MY_LIST.filter((item) => {
       let all_str = `${item[props.valuefield]}`.toLowerCase();
      //return all_str.indexOf(search) > -1; // View All When Search Empty
      return all_str.indexOf(search) > -1 && search;
    });
    setFilterlist(filteredList);
  }, [search]);


  if (isLoading) {
    return (
      <section>
       <p>Cargando...</p> 
      </section>
    );
  }

  if( lastURL !== props.url) {
    setLastURL(props.url);
  }

//  <label htmlFor={filtername}>{props.title}</label>
//  <input
//    id={filtername}
//    type="text"
//    className="form-control"
//    defaultValue={search}
//    placeholder={props.placeholder}
//    onKeyUp={onKeyUpHandler}
//  />

  return (
    <div>
      <div>
        <TextField
          id={filtername}
          type="text"
          size="large"
          label={props.title}
          defaultValue={search}
          placeholder={props.placeholder}
          onKeyUp={onKeyUpHandler}
        />
      </div>
      <div>
        <h6>
          Selección para {props.title} : {selected[props.valuefield]}
        </h6>
      </div>
      <ul className="list-group">
        {filterlist.map((item, index) => (
          <li
            className="list-group-item"
            key={index}
            value={item[props.valuefield]}
            onClick={(e) => onSelectItem(e, item)}
          >
            {item[props.valuefield]}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FilterList;