import React from "react";
import { useEffect, useContext, useState } from "react";
import LovContext from './store/lov-context';
import { Flex, TextField, Badge, Divider, Text } from '@aws-amplify/ui-react'


function OpcionVotos( props ) {
    const lovCtx = useContext(LovContext);
    let values = lovCtx.itemCounterValues(props.opcionid);

    const [field1, setField1] = useState(values && values !== undefined ? values.value1 : "NaN");
    const [field2, setField2] = useState(values && values !== undefined ? values.value2 : "NaN");

    const [haserror, setHasError] = useState(values && values !== undefined ? values.value1 === values.value2 : true);
  
    let numdigits = parseInt(props.maxdigits);
    let errorbadge = [];

    function compareFields(){
        lovCtx.removeCounter(props.opcionid);
        lovCtx.addCounter( { id: props.opcionid, value1: field1, value2: field2 });
        if(field1 === field2 && !isNaN(field1) && !isNaN(field2)){
            console.info( 'Iguales: ', field1, field2 );
            setHasError(false);
         //Code for passwords being equal goes here
        }
        else {
            console.info( 'Distintos: ', field1, field2 );
            setHasError(true);
        }
    }

    useEffect(() => {
        console.log("useEffect");
        compareFields();
    }, [field1, field2]);
    

    if( haserror ){
        errorbadge.push(<Badge width="7%" size="small" variation="error">X</Badge>);
    }
    else {
        errorbadge.push(<Badge width="7%" size="small" variation="success"></Badge>);
    }

    return (
        <div>
           <Divider
            orientation="horizontal" />
            <Flex
                direction="row"
                justifyContent="flex-end"
                alignItems="baseline"
                alignContent="flex-end"
                wrap="nowrap"
                gap="0.4rem"
                >
                <Text width="30%"
                    lineHeight="1.5em"
                    fontWeight={400}
                    fontSize="1em"
                    fontStyle="normal"
                 >{props.title}
                </Text>
                <TextField
                width="33%"
                autoComplete="off"
                descriptiveText=""
                direction="column"
                hasError={false}
                inputMode="number"
                isDisabled={false}
                isReadOnly={false}
                isRequired={true}
                label=" "
                value={values && values !== undefined ? values.value1 : "" }
                labelHidden={true}
                name="votos1"
                placeholder="Ingrese"
                type="number"
                wrap="nowrap"
//                onInput={(e) => console.info('input fired:', e.currentTarget.value)}
                onInput = {(e) =>{
                                e.target.value = e.target.value === "" ? "" : Math.max(0, parseInt(e.target.value) ).toString().slice(0,numdigits);
                                setField1(e.target.value);
                           }}
//                onChange={(e) => console.info(e.currentTarget.value)}
//                onChange={e => compareFields(e)} 
                onCopy={(e) => console.info('onCopy fired:', e.currentTarget.value)}
                onCut={(e) => console.info('onCut fired:', e.currentTarget.value)}
                onPaste={(e) => console.info('onPaste fired:', e.currentTarget.value)}
                onSelect={(e) =>
                    console.info(
                    'onSelect fired:',
                    e.currentTarget.value.substring(
                        e.currentTarget.selectionStart,
                        e.currentTarget.selectionEnd
                    )
                    )
                }
                />                
            <TextField
                width="33%"
                autoComplete="off"
                descriptiveText=""
                direction="column"
                hasError={haserror}
                inputMode="number"
                isDisabled={false}
                isReadOnly={false}
                isRequired={true}
                label=" "
                value={values && values !== undefined ? values.value2 : "" }
                labelHidden={true}
                name="votos2"
                placeholder="Confirme"
                type="number"
                wrap="nowrap"
//                onInput={(e) => console.info('input fired:', e.currentTarget.value)}
                onInput = {(e) =>{
                        e.target.value = e.target.value === "" ? "" : Math.max(0, parseInt(e.target.value) ).toString().slice(0,numdigits)
                        setField2(e.target.value)
                    }}
//                onChange={(e) => console.info(e.currentTarget.value)}
//                onChange={e => compareFields(e)} 
                onCopy={(e) => console.info('onCopy fired:', e.currentTarget.value)}
                onCut={(e) => console.info('onCut fired:', e.currentTarget.value)}
                onPaste={(e) => console.info('onPaste fired:', e.currentTarget.value)}
                onSelect={(e) =>
                    console.info(
                    'onSelect fired:',
                    e.currentTarget.value.substring(
                        e.currentTarget.selectionStart,
                        e.currentTarget.selectionEnd
                    )
                    )
                }
                />
                {errorbadge}
            </Flex>
        </div>
    );

}

export default OpcionVotos;