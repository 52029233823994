import React from "react";
import { useEffect, useContext, useState } from "react";

import { Flex, Button, Image } from '@aws-amplify/ui-react';

import ConteoPage from './pages/ConteoPage';
import InhabilitadosPage from './pages/InhabilitadosPage';
import ComoLlegarPage from './pages/ComoLlegarPage';
import ValidacionMesaPage from './pages/ValidacionMesasPage';
import LovContext from "./components/store/lov-context";

import './App.css';

import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import { I18n } from "aws-amplify";

const authScreenLabels = {
  es: {
    "Create Account": "Crear Cuenta",
    "Sign In": "Iniciar Sesión",
    "Sign in": "Iniciar sesión",
    "Forgot your password?": "Olvido su contraseña?",
    "Reset your password": "Restablezca su contraseña",
    "Enter your email": "Ingrese su email",
    "Send code": "Enviar código",
    "Back to Sign In": "Volver al inicio de sesión",
    "Email": "Email",
    "Password": "Contraseña",
    "Confirm Password": "Confirme Contraseña",
    "Signing in": "Iniciando sesión",
    "User does not exist.": "El usuario no existe.",
    "Incorrect username or password.": "Nombre de usuario o contraseña incorrecta.",
    "Code": "Código recibido en email",
    "New Password": "Nueva Contraseña",
    "Submit": "Enviar",
    "Resend Code": "Reenviar Código",
    "Submitting": "Enviando",
    "Sending...": "Enviando...",
    "Attempt limit exceeded, please try after some time.": "Se excedió el límite de intentos, intente después de un tiempo.",
  },
};

I18n.setLanguage("es");
I18n.putVocabularies(authScreenLabels);

Amplify.configure(awsExports);

function App({ signOut, user }) {

  const [selectedPage, setSelectedPage] = useState("Main");

  const lovCtx = useContext(LovContext);

  useEffect(() => {
    lovCtx.resetContext();
  }, [selectedPage]);


  function salirApp() {
    lovCtx.resetContext();
    signOut();
  }

  if(selectedPage === "InhabilitadosPage") {
    return( <InhabilitadosPage />);
  }

  if(selectedPage === "ConteoPage")  {
    return( <ConteoPage user={user} />);
  }
  
  if(selectedPage === "ComoLlegarPage")  {
    return( <ComoLlegarPage user={user} />);
  }

  if(selectedPage === "ValidacionMesasPage")  {
    return( <ValidacionMesaPage user={user} />);
  }


  return (
      <Flex direction="column" alignItems="center" alignContent="center">
        <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("InhabilitadosPage")}>Inhabilitados para Votar</Button>
        <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("ConteoPage")}>Registro de Resultado de Mesa</Button>
        <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("ComoLlegarPage")}>Como LLegar al Local</Button>
        <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("ValidacionMesasPage")}>Validación Resultados de Mesas</Button>
        <Button isFullWidth={true} variation="primary" onClick={() => salirApp()}>Salir de la Aplicación</Button>
        <Image
            alt="Team Patriota"
            src="TeamPatriota.png"
            height="30%"
            width="30%"
          />
      </Flex>
);
}

export default withAuthenticator(App);
