import React from "react";
import { useEffect, useContext, useState } from "react";

import App from "../App";
import "../App.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import FilterList from "../components/filterlist";
import DropDownList from "../components/dropdownlov";
import DetallesLocal from "../components/detalleslocal";
import LovContext from "../components/store/lov-context";
import { Card, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { usePosition } from "../usePosition";

function ComoLlegarPage({ signOut, user }) {
  const [selectedPage, setSelectedPage] = useState("Main");
  const lovCtx = useContext(LovContext);

  let content = [];
  let idRegion;
  let idComuna;
  let idLocal;

  if (selectedPage === "App") {
    return <App />;
  }

  if (lovCtx.totalLov >= 0) {
    content.push(
      <DropDownList
        url="https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getregiones"
        lovid="1"
        listname="regiones"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Región"
        placeholder="Ingrese nombre de región"
      />
    );
  }
  if (lovCtx.totalLov >= 1) {
    idRegion = lovCtx.itemLovSelectedValue(1 + "").value;
    let urlstr =
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getcomunas/" +
      idRegion;
    content.push(
      <DropDownList
        url={urlstr}
        lovid="2"
        listname="comunas"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Comuna"
        placeholder="Ingrese nombre de comuna"
      />
    );
  }
  if (lovCtx.totalLov >= 2) {
    idComuna = lovCtx.itemLovSelectedValue(2 + "").value;
    let urlstr =
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getlocales/" +
      idRegion +
      "/" +
      idComuna;
    content.push(
      <DropDownList
        url={urlstr}
        lovid="3"
        listname="locales"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Local"
        placeholder="Seleccione Local"
      />
    );
  }
  if (lovCtx.totalLov >= 3) {
    idLocal = lovCtx.itemLovSelectedValue(3 + "").value;
    let urlstr =
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getdetallelocal/" +
      idLocal;
    content.push(
      <DetallesLocal
        url={urlstr}
      />
    );
  }

  return (
    <Card variation="outlined">
      <Button
        isFullWidth={true}
        variation="primary"
        onClick={() => setSelectedPage("App")}
      >
        Volver al Menú Principal
      </Button>
      {content}
    </Card>
  );
}

export default ComoLlegarPage;
