import React from "react";
import { useEffect, useContext, useState } from "react";
import "../App.css";
import App from "../App";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import FotoActa from "../components/fotoacta";
import DropDownList from "../components/dropdownlov";
import LovContext from "../components/store/lov-context";
import { Card, Button, Flex, Divider, Text } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";

import awsExports from "../aws-exports";
import { I18n } from "aws-amplify";
import OpcionVotos from "../components/opcionvotos";
import { usePosition } from '../usePosition';

const authScreenLabels = {
  es: {
    "Create Account": "Crear Cuenta",
    "Sign In": "Iniciar Sesión",
    "Sign in": "Iniciar sesión",
    "Forgot your password?": "Olvido su contraseña?",
    "Reset your password": "Restablezca su contraseña",
    "Enter your email": "Ingrese su email",
    "Send code": "Enviar código",
    "Back to Sign In": "Volver al inicio de sesión",
    "Email": "Email",
    "Password": "Contraseña",
    "Confirm Password": "Confirme Contraseña",
    "Signing in": "Iniciando sesión",
    "User does not exist.": "El usuario no existe.",
    "Incorrect username or password.": "Nombre de usuario o contraseña incorrecta.",
    "Code": "Código recibido en email",
    "New Password": "Nueva Contraseña",
    "Submit": "Enviar",
    "Resend Code": "Reenviar Código",
    "Submitting": "Enviando",
    "Sending...": "Enviando...",
    "Attempt limit exceeded, please try after some time.": "Se excedió el límite de intentos, intente después de un tiempo.",
  },
};

I18n.setLanguage("es");
I18n.putVocabularies(authScreenLabels);

Amplify.configure(awsExports);

let errMessages = [];
let warningMessages = [];



function ConteoPage(props) {
  const watch = true;
  const {
    latitude,
    longitude,
    speed,
    timestamp,
    accuracy,
    heading,
    error,
  } = usePosition(watch);

  const user = props.user;


  let postResultado = {
    NRO_REGION: null,
    COMUNA: null,
    LOCAL: null,
    MESAS_FUSIONADAS: null,
    CANTIDAD_VOTOS_APRUEBO: null,
    CANTIDAD_VOTOS_RECHAZO: null,
    CANTIDAD_VOTOS_NULOS: null,
    CANTIDAD_VOTOS_EN_BLANCO: null,
    CANTIDAD_VOTOS_MESA: null,
    ACTA: null,
    USUARIO : null,
    LATITUD : null,
    LONGITUD : null
  };
  const [submitFormMark, setSubmitFormMark] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [skipWarnings, setSkipWarnings] = useState(false);
  const [envioExitoso, setEnvioExitoso] = useState(false);
  const [togleFlag, setTogleFlag] = useState(false);
  const [fotografiarFlag, setFotografiarFlag] = useState(false);
  const [selectedPage, setSelectedPage] = useState("Main");

  let content = [];
  const lovCtx = useContext(LovContext);


  function salirReporteErrores() {
    errMessages = [];
    warningMessages = [];
    setEnvioExitoso(false);
    setSkipWarnings(false);
    setTogleFlag(!togleFlag);
  }

  function postData() {
    // POST request using fetch with error handling
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postResultado),
    };
    fetch(
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/putmesa",
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setEnvioExitoso(true);
        errMessages = [];
      })
      .catch((error) => {
        errMessages.push(<p>{error}</p>);
        setEnvioExitoso(false);
        console.error("Se produjo un error! ", error);
      });
  }

  function checkData() {
    let error;
    let region = lovCtx.itemLovSelectedValue("1").value;
    let comuna = lovCtx.itemLovSelectedValue("2").value;
    let local = lovCtx.itemLovSelectedValue("3").value;
    let mesa = lovCtx.itemLovSelectedValue("4").value;
    let votosapruebo = parseInt(lovCtx.itemCounterValue("1"));
    let votosrechazo = parseInt(lovCtx.itemCounterValue("2"));
    let votosnulos = parseInt(lovCtx.itemCounterValue("3"));
    let votosblancos = parseInt(lovCtx.itemCounterValue("4"));
    let votostotal = parseInt(lovCtx.itemCounterValue("5"));
    let fotoActa = lovCtx.getFotoActa();
    let retwarning = false;
    let retsuccess = true;

    if (region === "0") {
      error = "Debe seleccionar Región";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    }
    if (comuna === "0") {
      error = "Debe seleccionar Comuna";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    }
    if (local === "0") {
      error = "Debe seleccionar Local";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    }
    if (mesa === "0") {
      error = "Debe seleccionar Mesa";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    }
    if (isNaN(votosapruebo)) {
      error = "Debe ingresar Votos A Favor";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    } else {
      if (!lovCtx.itemCounterAreEqual("1")) {
        error = "Votos A Favor: Ingresado y Verificación difieren";
        errMessages.push(<p>{error}</p>);
        retsuccess = false;
        console.info("Se produjo un error! ", error);
      }
    }
    if (isNaN(votosrechazo)) {
      error = "Debe ingresar Votos En Contra";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    } else {
      if (!lovCtx.itemCounterAreEqual("2")) {
        error = "Votos En Contra: Ingresado y Verificación difieren";
        errMessages.push(<p>{error}</p>);
        retsuccess = false;
        console.info("Se produjo un error! ", error);
      }
    }
    if (isNaN(votosnulos)) {
      error = "Debe ingresar Votos Nulos";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    } else {
      if (!lovCtx.itemCounterAreEqual("3")) {
        error = "Votos Nulos: Ingresado y Verificación difieren";
        errMessages.push(<p>{error}</p>);
        retsuccess = false;
        console.info("Se produjo un error! ", error);
      }
    }
    if (isNaN(votosblancos)) {
      error = "Debe ingresar Votos Blancos";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    } else {
      if (!lovCtx.itemCounterAreEqual("4")) {
        error = "Votos Blancos: Ingresado y Verificación difieren";
        errMessages.push(<p>{error}</p>);
        retsuccess = false;
        console.info("Se produjo un error! ", error);
      }
    }
    if (isNaN(votostotal)) {
      error = "Debe ingresar Total de Votos";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    } else {
      if (!lovCtx.itemCounterAreEqual("5")) {
        error = "Total Votos Mesa: Ingresado y Verificación difieren";
        errMessages.push(<p>{error}</p>);
        retsuccess = false;
        console.info("Se produjo un error! ", error);
      }
    }
    if (fotoActa.length === 0) {
      error = "Falta Foto del Acta";
      errMessages.push(<p>{error}</p>);
      retsuccess = false;
      console.info("Se produjo un error! ", error);
    }
    retwarning = false;
    if (
      votostotal !==
      votosapruebo + votosrechazo + votosnulos + votosblancos
    ) {
      error = `El Total Votos Mesa ${votostotal} difiere de suma de:`;
      warningMessages.push(<p>{error}</p>);
      console.info("Se produjo un error! ", error);
      retwarning = true;
    }
    error = `Votos A Favor: ${votosapruebo}`;
    warningMessages.push(<p>{error}</p>);
    error = `Votos En Contra: ${votosrechazo}`;
    warningMessages.push(<p>{error}</p>);
    error = `Votos Nulos: ${votosnulos}`;
    warningMessages.push(<p>{error}</p>);
    error = `Votos Blancos: ${votosblancos}`;
    warningMessages.push(<p>{error}</p>);
    if( !retwarning ) {
      error = `Total Votos Mesa: ${votostotal}`;
      warningMessages.push(<p>{error}</p>);
    }
    retwarning = true;
    error = "Por favor, verifique que los votos registrados en cada categoría corresponden a lo registrado en el ACTA";
    warningMessages.push(<p>{error}</p>);

    let ret = 0;
    if (retsuccess && !retwarning) {
      ret = 1;
    } else if (retwarning) {
      if (skipWarnings) {
        ret = 1;
      } else {
        ret = 2;
      }
    } else {
      ret = 0;
    }
    if( ret ===  1) {
      postResultado.NRO_REGION = region;
      postResultado.COMUNA = comuna;
      postResultado.LOCAL = local;
      postResultado.MESAS_FUSIONADAS = mesa;
      postResultado.CANTIDAD_VOTOS_APRUEBO = votosapruebo;
      postResultado.CANTIDAD_VOTOS_RECHAZO = votosrechazo;
      postResultado.CANTIDAD_VOTOS_NULOS = votosnulos;
      postResultado.CANTIDAD_VOTOS_EN_BLANCO = votosblancos;
      postResultado.CANTIDAD_VOTOS_MESA = votostotal;
      postResultado.ACTA = fotoActa;
      postResultado.USUARIO = user.attributes.email;
      postResultado.LATITUD = latitude && latitude !== undefined ? latitude : -1;
      postResultado.LONGITUD = longitude && longitude !== undefined ? longitude : -1;  
    }
    return ret;
  }

  useEffect(() => {
    if (submitFormMark) {
      console.log("useEffect");
      setIsLoading(true);
      let ret = checkData();
      if (ret === 1) {
        postData();
      }
      setSubmitFormMark(false);
      setIsLoading(false);
      setSkipWarnings(false);
    }
  }, [submitFormMark]);

  // setSubmitFormMark(false);
  function submitForm() {
    errMessages = [];
    warningMessages = [];
    setSubmitFormMark(true);
  }

  function submitFormWithWarnings() {
    errMessages = [];
    warningMessages = [];
    setSkipWarnings(true);
    setSubmitFormMark(true);
  }

  if( selectedPage === "App") {
    return( <App />);
  }


  if (isLoading) {
    return (
      <section>
        <p>Enviando...</p>
      </section>
    );
  }

  function fotogfiarActa() {
    setFotografiarFlag( true )
  }

  function nofotografiarActa() {
    setFotografiarFlag( false )
  }

  if( envioExitoso ) {
    return(
      <Card variation="outlined">
        <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("App")}>Volver al Menú Principal</Button>
        <div>{user.attributes.email}, muchas gracias por su invaluable ayuda para cuidar nuestra democracia.</div>
      </Card>
    );
  }

  if( fotografiarFlag ) {
    return( 
      <Card variation="outlined">
        <FotoActa sacarfoto="1" solomostraracta="0"/>
        <Button
        isFullWidth={true}
        variation="primary"
        size="large"
        loadingText=""
        onClick={() => nofotografiarActa()}
        ariaLabel=""
      >
        Listo
      </Button>
      </Card>
    );

  }

  if (errMessages.length !== 0) {
    return (
      <Card variation="outlined">
        <Text
          variation="warning"
          as="strong"
          color="blue"
          lineHeight="1.5em"
          fontWeight={400}
          fontSize="1em"
          fontStyle="normal"
          textDecoration="none"
          width="30vw"
        >
          Por favor, corrija las siguientes situaciones:
        </Text>
        {errMessages}
        <Button isFullWidth={true} variation="primary" onClick={() => salirReporteErrores()}>Volver al Ingreso</Button>
     </Card>
    );
  }
  if (warningMessages.length !== 0) {
    return (
      <Card variation="outlined">
        <Text
          variation="warning"
          as="strong"
          color="blue"
          lineHeight="1.5em"
          fontWeight={400}
          fontSize="1em"
          fontStyle="normal"
          textDecoration="none"
          width="30vw"
        >
          Revisar lo siguiente:
        </Text>
        {warningMessages}
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          alignContent="flex-end"
          wrap="nowrap"
          gap="1rem"
        >
          <Button variation="primary" onClick={() => submitFormWithWarnings()}>
            Est&aacute; Correcto
          </Button>
          <Button variation="primary" onClick={() => salirReporteErrores()}>Debo Corregir</Button>
        </Flex>
      </Card>
    );
  }


  let idRegion;
  let idComuna;
  let idLocal;

  if (lovCtx.totalLov >= 0) {
    content.push(
      <DropDownList
        url="https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getregiones"
        lovid="1"
        listname="regiones"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Región"
        placeholder="Seleccione Región"
      />
    );
  }
  if (lovCtx.totalLov >= 1) {
    idRegion = lovCtx.itemLovSelectedValue(1 + "").value;
    let urlstr =
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getcomunas/" +
      idRegion;
    content.push(
      <DropDownList
        url={urlstr}
        lovid="2"
        listname="comunas"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Comuna"
        placeholder="Seleccione Comuna"
      />
    );
  }
  if (lovCtx.totalLov >= 2) {
    idComuna = lovCtx.itemLovSelectedValue(2 + "").value;
    let urlstr =
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getlocales/" +
      idRegion +
      "/" +
      idComuna;
    content.push(
      <DropDownList
        url={urlstr}
        lovid="3"
        listname="locales"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Local"
        placeholder="Seleccione Local"
      />
    );
  }
  if (lovCtx.totalLov >= 3) {
    idLocal = lovCtx.itemLovSelectedValue(3 + "").value;
    let urlstr =
      "https://zkt7kkizv4.execute-api.us-east-1.amazonaws.com/prod/getmesas/" +
      idRegion +
      "/" +
      idComuna +
      "/" +
      idLocal;
    content.push(
      <DropDownList
        url={urlstr}
        lovid="4"
        listname="mesas"
        keyfield="CODIGO"
        valuefield="DESCRIPCION"
        title="Mesa"
        placeholder="Seleccione Mesa"
      />
    );
  }
  if (lovCtx.totalLov >= 4) {
    content.push( 
      <><Divider
      size="large"
      orientation="horizontal" />
      </>
    );
    content.push(
      <Text     
      lineHeight="1.5em"
      fontWeight={950}
      fontSize="1em"
      fontStyle="normal"
      textDecoration="none"
      >
        Registro de Votos Consignados en Acta
      </Text>
    );

    content.push(
      <OpcionVotos opcionid="1" title="A Favor" maxdigits="3" /> 
    );
    content.push(
      <OpcionVotos opcionid="2" title="En Contra" maxdigits="3" />
    );
    content.push(
      <OpcionVotos opcionid="3" title="Nulos" maxdigits="3" />
    );
    content.push(
      <OpcionVotos opcionid="4" title="Blancos" maxdigits="3" />
    );
    content.push(
      <OpcionVotos opcionid="5" title="Total Acta" maxdigits="3" />
    );
    if(lovCtx.fotoActa.length !== 0) {
      content.push(
        <><Divider orientation="horizontal" /><Divider orientation="horizontal" /><Divider orientation="horizontal" /><Button
          isFullWidth={true}
          variation="primary"
          size="large"
          loadingText=""
          onClick={() => submitForm()}
          ariaLabel=""
        >
          Enviar Resultado
        </Button></>
      );  
    }
    content.push( <><Divider orientation="horizontal" /><Divider orientation="horizontal" /><Divider orientation="horizontal" /><Button
      isFullWidth={true}
      variation="primary"
      size="large"
      loadingText=""
      onClick={() => fotogfiarActa()}
      ariaLabel=""
    >
      { lovCtx.fotoActa.length === 0 ? "Fotografiar Acta" : "Volver a Fotografiar Acta" }
    </Button></>
    );
    content.push(<FotoActa sacarfoto="0" solomostraracta="1"/>);
  }

  return (
    <Card variation="outlined">
      <Button isFullWidth={true} variation="primary" onClick={() => setSelectedPage("App")}>Volver al Menú Principal</Button>    
      <div>{errMessages}</div>
      {content}
    </Card>
  );
}

export default ConteoPage;