import React from "react";
import { useEffect, useContext, useState } from "react";
import LovContext from './store/lov-context';
import { Divider, Text, Link, Flex } from '@aws-amplify/ui-react'

let getresultado = {
    COD_REGION: "13",
    REGION: "METROPOLITANA DE SANTIAGO",
    COD_COMUNA: "13106",
    COMUNA: "ESTACION CENTRAL",
    COD_CIRCUNSCRIPCION: "13",
    CIRCUNSCRIPCION: "LAS AMERICAS",
    COD_LOCAL: "1",
    LOCAL: "ESCUELA CAROLINA VERGARA AYARES D-276 L1",
    DIRECCION: "COYHAIQUE 6055",
    LATITUD: "-33.4735",
    LONGITUD: "-70.7051",
    CANTIDAD_MESAS: "13",
    CANTIDAD_ELECTORES: "5200"
}
;

function DetallesLocal(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [lastURL, setLastURL] = useState("");

  function fetchData() {
    fetch(props.url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        getresultado = data;
       setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log("useEffect");
    fetchData();
  }, [lastURL]);

  if( lastURL !== props.url) {
    setLastURL(props.url);
  }

  if (isLoading) {
    return (
      <section>
       <p>Cargando...</p> 
      </section>
    );
  }

  let latlong = "https://www.google.com/maps/search/?api=1&query=" + getresultado.LATITUD + "," + getresultado.LONGITUD;
  return (
    <Flex
    direction="column"
    justifyContent="flex-start"
    alignItems="baseline"
    alignContent="flex-start"
    wrap="nowrap"
    gap="0.4rem"
    >
    <Text variation="primary"></Text>
    <Text variation="primary"></Text>
    <Text variation="primary"></Text>
    <Divider
    label="Dirección"
    size="large"
    orientation="horizontal" />
    <Text variation="primary"></Text>
    <Text variation="primary"></Text>
    <Text variation="primary">{getresultado.DIRECCION}</Text>
    <Text variation="primary"></Text>
    <Divider
    label="Latitud y Longitud"
    size="large"
    orientation="horizontal" />
    <Link
        href={latlong}
        color="#007EB9"
    >
        Abrir ubicación en Google Maps
    </Link>
    </Flex>
);
}

export default DetallesLocal;